<script lang="ts">
	import { run } from 'svelte/legacy';

	import { Loader2, Check, X } from 'lucide-svelte';

	type State = 'default' | 'success' | 'loading' | 'error';
	let styling: string = $state('');
	const default_styling: string =
		'text-black bg-zinc-100 hover:text-white hover:bg-gradient-to-t from-orange-500 to-orange-400 h-9';

	let size: string = $state('9');

	const discord_styling: string = 'bg-[#5865f2] hover:bg-[#6a75f2] rounded-md h-11';

	const steam_styling: string =
		'bg-zinc-950 hover:bg-neutral-900 rounded-md border border-neutral-800 h-11';

	const green_styling: string =
		'text-black bg-zinc-100 hover:text-white hover:bg-gradient-to-t from-green-700 to-green-500 h-9';

	interface Props {
		button_state?: State;
		style?: string;
		disabled?: boolean | undefined;
		type?: 'button' | 'submit' | 'reset' | null | undefined;
		functionToRun?: (event: MouseEvent) => void;
		children?: import('svelte').Snippet;
	}

	let {
		button_state: button_state = 'default',
		style = 'default',
		disabled = undefined,
		type = 'button',
		functionToRun = () => {},
		children
	}: Props = $props();
	run(() => {
		switch (style) {
			case 'default':
				styling = default_styling;
				break;
			case 'discord':
				styling = discord_styling;
				size = '11';
				break;
			case 'steam':
				styling = steam_styling;
				size = '11';
				break;
			case 'green':
				styling = green_styling;
				break;
		}
	});
</script>

<div class="w-full">
	{#if button_state !== 'default'}
		<button
			class:loading={button_state === 'loading'}
			class:success={button_state === 'success'}
			class:error={button_state === 'error'}
			class="flex justify-center w-full py-1 transition-all duration-300 border rounded cursor-progress focus:outline-none h-{size} items-center"
		>
			{#if button_state === 'loading'}
				<Loader2 class="animate-spin" color="#eab308" />
			{:else if button_state === 'success'}
				<Check class="stroke-green-500" />
			{:else}
				<X class="stroke-red-500" />
			{/if}
		</button>
	{:else}
		<button
			{disabled}
			class="disabled:opacity-50 disabled:saturate-0 disabled:cursor-not-allowed w-full px-2 py-1 text-lg {styling} transition-all duration-100 rounded cursor-pointer xl:text-base focus:outline-none focus:ring focus:border-blue-500 focus:border-2"
			{type}
			onclick={functionToRun}
		>
			<div>{@render children?.()}</div>
		</button>
	{/if}
</div>

<style>
	.loading {
		border-color: rgb(234 179 8);
		background-color: rgb(234 179 8 / 0.3);
	}

	.success {
		border-color: rgb(34 197 94);
		background-color: rgb(34 197 94 / 0.3);
	}

	.error {
		border-color: rgb(197 34 34);
		background-color: rgb(197 34 34 / 0.3);
	}
</style>
